interface User {
	client_name: string,
	created_at: string,
	email: string,
	id: string,
	role: string,
	updated_at: string,
	user: string,
	pass: string,
	worker_ip: string
}

export const user: User = {
	client_name: 'Super Admin',
	created_at: '2023-02-02T10:18:21.000Z',
	email: 's.georgiev@tvm-engineering.com',
	id: 'aaa99639-b96d-4368-b22a-a1c3daabcaf9',
	role: 'super-admin',
	updated_at: "",
	user: 'Elon Musk',
	pass: 'SpaceX',
	worker_ip: '127.0.0.1'
};

export const checkUserExist = () => {
    const userLS = JSON.parse(localStorage.getItem('user') as any)
    
    if (!userLS) {
        localStorage.setItem("user", JSON.stringify(user))
    }

	return true
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user') as any)
}

export const editUser = (user: User) => {
	localStorage.setItem("user", JSON.stringify(user))
}
