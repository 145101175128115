import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip
} from '@mui/material';
import { CSVLink, CSVDownload } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { websitesServices } from '../../services/websites';

const CustomTable = ({
	data,
	initialData,
	url,
	isCurrentWebsiteSaved,
	setIsCurrentWebsitedSaved
}: any) => {
	// const [isLoading, setIsLoading] = useState<boolean>(false);
	// const classes = useStyles();

	// Create
	// const [isWebsiteSaved, setIsWebsitedSaved] = useState<boolean>(false)

	const queryClient = useQueryClient();

	const createWebsiteMutation = useMutation({
		mutationFn: websitesServices.createWebsite,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['websites'], exact: true });
			setIsCurrentWebsitedSaved(true);
		}
	});

	function handleSaveWebsite() {
		createWebsiteMutation.mutate({
			company_name: initialData?.companyName,
			email: initialData?.email,
			employees: initialData?.employees,
			expertise: initialData?.expertise,
			industries: initialData?.industries,
			phone: initialData?.phone,
			products: initialData?.products,
			region: initialData?.region,
			services: initialData?.services,
			url: url
		});
		console.log('website data', initialData);
	}

	return (
		<div style={{ display: 'flex' }}>
			{/* <div style={{ display: "flex", justifyContent: "flex-end", alignContent: 'center' }}>
				<CSVLink data={data} style={{ padding: "5px", display: "flex" }} filename='trade-fair-app-data.csv'>
			<Tooltip title="Download as CSV" arrow placement='left'>
					<DownloadIcon />
			</Tooltip>
				</CSVLink>
			</div> */}
			<Paper
				elevation={3}
				sx={{ minWidth: '700px', minHeight: '100px', maxWidth: '700px' }}
			>
				<TableContainer>
					<Table>
						<TableBody>
							{data.map((row: any, index: number) => (
								<TableRow key={index}>
									<TableCell
										style={{
											fontWeight: 'bold',
											width: '120px',
											color: 'rgb(31 121 194)',
											borderRight: '1px solid rgba(224, 224, 224, 1)'
										}}
									>
										{row.attribute}
									</TableCell>
									<TableCell>{row.value}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<div>
				<Button
					onClick={handleSaveWebsite}
					variant="contained"
					sx={{ marginLeft: '5px', textTransform: 'capitalize' }}
					disabled={isCurrentWebsiteSaved}
				>
					Save
				</Button>
			</div>
		</div>
	);
};
export default CustomTable;
