// import ChatBot from '../../components/openai/chatbot';
import FullScreenChat from '../../components/openai/FullScreenChat/FullScreenChat';
import TicketsTableComponent from '../../components/tables/TicketsTableComponent';

const ChatbotPage = () => {
	return (
		<div
			style={{
				display: 'flex',
				// flexDirection: 'column',
				// justifyContent: 'center',
				height: '80vh',
				width: 'auto',
				alignItems: 'center',
				justifyContent: 'space-evenly'
			}}
		>
			<FullScreenChat />
			<TicketsTableComponent />
		</div>
	);
};

export default ChatbotPage;
