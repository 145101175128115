/**
 * Copyright (C) 2019-present TVM Engineering Authors.
 */

import Cookie from 'universal-cookie';
import { redirect } from 'react-router-dom';
import { actions as CommonActions } from '../store/reducers/app';
import types from '../store/actions/types';
// import { history } from '../history';

export default function idleListener(dispatch: any) {
  let t: any | undefined;
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;  // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer;      // catches touchpad clicks as well
  window.onkeypress = resetTimer;
  window.addEventListener('scroll', resetTimer, true); // improved; see comments

  /*
  function showNotification() {
    dispatch(CommonActions.showNotification(
      'You have been inactive for 5 minute(s). You will be logged off automatically.',
      true,
      true,
      'info'
    ));
  }
  */
	const cookie = new Cookie();

  function logout() {
    dispatch({ type: types.CONFIRMING_USER_ACCOUNT, payload: 'You have been inactive for 5 minute(s). You will be logged off automatically.' });
    cookie.remove('token', { path: '/' });
    cookie.remove('user', { path: '/' });
		redirect('/login');
    dispatch(CommonActions.goOffline());
    dispatch({
      type: types.DEVICE_IS_LOADING,
      payload: false
    });
  }

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(logout, 300000);  // time is in milliseconds
  }
}
