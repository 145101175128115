import { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Tooltip
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ticketsService } from '../../services/tickets';
import { getDateTime } from '../../utils/misc';

export default function TicketsTableComponent() {
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(0);

	const getAllTickets = useQuery({
		queryKey: ['tickets'],
		queryFn: ticketsService.getAllTickets
	});

	if (getAllTickets.status === 'pending') {
		return <h1>Loading...</h1>;
	}

	if (getAllTickets.status === 'error') {
		return <h1>{JSON.stringify(getAllTickets.error)}</h1>;
	}

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Paper sx={customTableStyles.paperComponent}>
			<p style={{ textAlign: 'center', fontSize: '1.5rem' }}>Open Tickets</p>
			<TableContainer>
				<Table sx={customTableStyles.tableStyles} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={customTableStyles.cellStyles}>ID</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Content
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Created At
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{getAllTickets.data.data.tickets
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row: any) => (
								<TableRow key={row.id} sx={customTableStyles.cellStyles3}>
									<TableCell
										component="th"
										scope="row"
										sx={{ fontFamily: 'Poppins' }}
									>
										{row.id?.slice(0, 15).concat('...')}
									</TableCell>
									<TableCell align="left">
										<Tooltip followCursor title={row.content}>
											{row.content?.length <= 30
												? row.content
												: row.content?.slice(0, 35).concat('...')}
										</Tooltip>
									</TableCell>
									<TableCell align="left">
										{getDateTime(row.createdAt)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={getAllTickets.data.data.tickets?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={{
					'.MuiIconButton-root': { color: '#1E9DF2' },
					'.MuiTablePagination-displayedRows': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					},
					'.MuiTablePagination-select': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					},
					'.MuiTablePagination-selectIcon': {
						color: '#1E9DF2'
					},
					'.MuiTablePagination-selectLabel': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					}
				}}
			/>
		</Paper>
	);
}

const customTableStyles = {
	tableStyles: {
		borderTop: '1px solid #E8E8E9',
		borderBottom: '1px solid #E8E8E9',
		borderLeft: '1px solid #E8E8E9',
		borderRight: '1px solid #E8E8E9',
		boxShadow: 'none',
		maxWidth: '700px',
		// marginTop: '-40px',
		minWidth: '700px'
		// border: "2px solid red",
	},
	cellStyles: {
		borderTop: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderBottom: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderLeft: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		color: '#1B1E1F',
		fontSize: '11px',
		fontWeight: '500',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// height: "35px",
		// minWidth: "70px",
		// padding: '8px 12px',
		// width: "max-content",
		// '& .MuiTableSortLabel-root': {
		// 	width: 'max-content'
		// },
		backgroundColor: '#F4F4F4'
	},
	cellStyles2: {
		border: '1px solid #E8E8E9',
		fontSize: '11px',
		fontWeight: '400',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '0px 12px',
		color: '#1B1E1F'
	},
	cellStyles3: {
		border: '1px solid #E8E8E9',
		fontSize: '11px',
		fontWeight: '400',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '10px 12px',
		color: '#1B1E1F'
	},
	paperComponent: {
		width: '700px',
		minHeight: '460px',
		// mb: 2,
		boxShadow: 'none',
		background: 'transparent',
		marginTop: '-20px',
		fontFamily: 'Poppins'
	},
	cellStylesProject: {
		borderTop: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderBottom: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderLeft: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		color: '#1B1E1F',
		fontSize: '11px',
		fontWeight: '500',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '0px 12px',
		'& .MuiTableSortLabel-root': {
			width: 'max-content'
		},
		backgroundColor: '#F4F4F4'
	}
};
