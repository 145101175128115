export const data2022 = [10, 30, 20, 100, 80, 200, 35, 80, 90, 155, 115, 125];
export const data2023 = [20, 65, 40, 85, 150, 75, 215, 95, 105, 160, 325, 140];

export const columnData = [
    {
        name: 'Profit',
        data: [1265, 981, 1360, 1761, 1890, 1450, 1729, 1102, 1250, 1500, 1800, 2000]
    },
    {
        name: 'Expenses',
        data: [250, 200, 920, 638, 350, 400, 450, 500, 550, 600, 650, 700]
    }
];