import { Skeleton } from '@mui/material';

const TradeFairAppLoader = () => {
	const skeletonContainers = Array.from({ length: 9 }, (_, index) => (
		<div
			key={index}
			style={{ display: 'flex', justifyContent: 'space-between' }}
		>
			<Skeleton animation="wave" width={195} height={40} />
			<Skeleton animation="wave" width={500} />
		</div>
	));

	return <div style={{ width: '700px' }}>{skeletonContainers}</div>;
};

export default TradeFairAppLoader;
