import axios from "axios"
import { API_URL } from '../config';

type WebsiteProps = {
	url: string,
	company_name: string
	email: string
	employees: string
	expertise: string
	industries: string,
	phone: string,
	products: string,
	region: string,
	services: string,
}

export const websitesServices = {
  getAllTickets: () => axios.get(`${API_URL}/websites`),
	createWebsite: (data:WebsiteProps) => axios.post(`${API_URL}/websites/create`, data),
}
