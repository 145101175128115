/**
 * Copyright (C) 2019-present TVM Engineering Authors.
 */

import { actions as CommonActions } from '../store/reducers/app';

export default function onlineListener(dispatch: any) {
  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  function updateOnlineStatus() {
    if (navigator.onLine) {
      dispatch(CommonActions.goOnline());
      dispatch(CommonActions.showNotification(
        'Connected.',
        true,
        'success'
      ));
    } else {
      dispatch(CommonActions.goOffline());
      dispatch(CommonActions.showNotification(
        'No Internet Connection. Please check your connectivity.',
        true,
        'info'
      ));
    }
  }
}
