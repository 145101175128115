// import { Paper } from '@mui/material';

export type ShowCaseCardProps = {
	img: any;
	title: string;
	content: string;
	link: string;
};

// const ShowcaseCard = (props: ShowCaseCardProps) => {
// 	return (
// 		<Paper elevation={5} sx={cardStyles.cardContainer}>
// 			<div
// 				style={{
// 					display: 'flex',
// 					flexDirection: 'column',
// 					justifyContent: 'center',
// 					alignItems: 'center'
// 				}}
// 			>
// 				<div
// 					style={{
// 						border: '1px solid red',
// 						margin: '5px 10px',
// 						display: 'flex',
// 						justifyContent: 'center',
// 						padding: '5px'
// 					}}
// 				>
// 					<img width={304} height={217} src={props.img} alt="card icon" />
// 				</div>

// 				<div
// 					style={{ border: '1px solid green', width: '100%', display: 'flex' }}
// 				>
// 					{props.content}
// 				</div>
// 				<div
// 					style={{
// 						display: 'flex',
// 						justifyContent: 'flex-start',
// 						width: '100%',
// 						paddingTop: '5px'
// 					}}
// 				>
// 					<button>Learn more</button>
// 				</div>
// 			</div>
// 		</Paper>
// 	);
// };

// export default ShowcaseCard;

// const cardStyles = {
// 	cardContainer: {
// 		maxWidth: '392px',
// 		minWidth: '392px',
// 		minHeight: '477px',
// 		margin: '20px 40px',
// 		padding: '40px'
// 	}
// };

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Tooltip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

export default function ShowcaseCard(props: ShowCaseCardProps) {
	const { content, img, title, link } = props;

	const navigate = useNavigate();

	function navigateOnClick() {
		navigate(link);
	}

	return (
		<Card sx={{ maxWidth: 400 }}>
			<Tooltip title="Click to Learn More" followCursor>
				<Link to={link} style={{ textDecoration: 'none' }}>
					<CardMedia
						component="img"
						height="180"
						image={img}
						alt="green iguana"
					/>
					<CardContent>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							sx={{ color: 'rgb(23, 119, 203)' }}
						>
							{title}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{content}
						</Typography>
					</CardContent>
					{/* <CardActions>
						<Button
							size="small"
							color="info"
							onClick={() => navigateOnClick()}
							sx={{ textTransform: 'capitalize' }}
						>
							Learn More
						</Button>
					</CardActions> */}
				</Link>
			</Tooltip>
		</Card>
	);
}
