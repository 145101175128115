import './tradeFair.css';
import { useState, useEffect } from 'react';
import { crawlerAI, crawlerService } from '../../services/crawler';
import TradeFairCard, {
	TradeFairProps
} from '../../components/cards/TradeFairCard';
import LoadingImg from '../../assets/img/other/loading.gif';
// import { Button, TextField } from '@mui/material';
import TradeFairAppLoader from '../../components/loaders/TradeFairAppLoader';
import { useMutation } from '@tanstack/react-query';
import CustomTable from '../../components/tables/CustomTable';
import { CSVLink, CSVDownload } from 'react-csv';
import { Button, TextField } from '@mui/material';
import WebsitesTable from '../../components/tables/WebsitesTable';

const TradeFairApp = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [companyData, setCompanyData] = useState<TradeFairProps>({});
	const [url, setUrl] = useState<string>('');
	const [isCurrentWebsiteSaved, setIsCurrentWebsitedSaved] =
		useState<false>(false);

	const crawlMutation = useMutation({
		mutationFn: crawlerService.crawl,
		onSuccess: (data) => {
			console.log('data', data);
			setCompanyData(data?.data);
			setIsLoading(false);
		}
	});

	function onSubmit() {
		crawlMutation.mutate({ url });
		setIsLoading(true);
		setIsCurrentWebsitedSaved(false);
		setCompanyData({});
	}

	// useEffect(() => {
	// 	setIsLoading(true);
	// 	crawlerAI()
	// 		.then((data) => {
	// 			setCompanyData(data?.data);
	// 			setIsLoading(false);
	// 			return;
	// 		})
	// 		.catch((e) => console.error(e));
	// }, []);

	// if (crawlMutation.status === 'pending') {
	// 	return (
	// 		<div className="trade_fair_container">
	// 			<div
	// 				style={{
	// 					display: 'flex',
	// 					justifyContent: 'space-between',
	// 					alignItems: 'center',
	// 					width: '700px'
	// 				}}
	// 			>
	// 				<TradeFairAppLoader />
	// 			</div>
	// 		</div>
	// 	);
	// }

	if (crawlMutation.status === 'error') {
		return <p>{JSON.stringify(crawlMutation.error)}</p>;
	}

	return (
		<div className="trade_fair_container">
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					placeholder="Enter company url"
					sx={{ minWidth: '600px' }}
					onChange={(e: any) => setUrl(e.target.value)}
				/>
				<Button
					variant="contained"
					onClick={onSubmit}
					sx={{
						minWidth: '170px',
						marginLeft: '5px',
						textTransform: 'capitalize'
					}}
				>
					Collect Information
				</Button>
			</div>
			{isLoading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '700px',
						height: '500px'
					}}
				>
					<TradeFairAppLoader />
				</div>
			)}

			{/* <div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '700px'
				}}
			>
				<TradeFairAppLoader />
			</div> */}

			{Object.keys(companyData)?.length !== 0 ? (
				<CustomTable
					data={[
						{ attribute: 'Company', value: companyData?.companyName },
						{ attribute: 'Email', value: companyData?.email },
						{ attribute: 'Employees', value: companyData?.employees },
						{ attribute: 'Expertise', value: companyData?.expertise },
						{ attribute: 'Industries', value: companyData?.industries },
						{ attribute: 'Phone', value: companyData?.phone },
						{ attribute: 'Products', value: companyData?.products },
						{ attribute: 'Region', value: companyData?.region },
						{ attribute: 'Services', value: companyData?.services }
					]}
					initialData={companyData}
					url={url}
					isCurrentWebsiteSaved={isCurrentWebsiteSaved}
					setIsCurrentWebsitedSaved={setIsCurrentWebsitedSaved}
				/>
			) : null}
			{/* {Object.keys(companyData)?.length === 0 ? (
				<div style={{ height: '350px' }}></div>
			) : (
				// <TradeFairCard
				// 	companyName={companyData?.companyName}
				// 	email={companyData?.email}
				// 	employees={companyData?.employees}
				// 	expertise={companyData?.expertise}
				// 	industries={companyData?.industries}
				// 	phone={companyData?.phone}
				// 	products={companyData?.products}
				// 	region={companyData?.region}
				// 	services={companyData?.services}
				// />

				<CustomTable
					data={[
						{ attribute: 'Company', value: companyData?.companyName },
						{ attribute: 'Email', value: companyData?.email },
						{ attribute: 'Employees', value: companyData?.employees },
						{ attribute: 'Expertise', value: companyData?.expertise },
						{ attribute: 'Industries', value: companyData?.industries },
						{ attribute: 'Phone', value: companyData?.phone },
						{ attribute: 'Products', value: companyData?.products },
						{ attribute: 'Region', value: companyData?.region },
						{ attribute: 'Services', value: companyData?.services }
					]}
					initialData={companyData}
					url={url}
				/>
			)} */}
			<WebsitesTable />
		</div>
	);
};

export default TradeFairApp;
