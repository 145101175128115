import { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TablePagination,
	Tooltip
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { websitesServices } from '../../services/websites';
import { getDateTime } from '../../utils/misc';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

export default function WebsitesTable() {
	const [rowsPerPage, setRowsPerPage] = useState<number>(5);
	const [page, setPage] = useState<number>(0);

	const getWebsites = useQuery({
		queryKey: ['websites'],
		queryFn: websitesServices.getAllTickets
	});

	if (getWebsites.status === 'pending') {
		return <h1>Loading...</h1>;
	}

	if (getWebsites.status === 'error') {
		return <h1>{JSON.stringify(getWebsites.error)}</h1>;
	}

	const handleChangePage = (event: any, newPage: any) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Paper sx={customTableStyles.paperComponent}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignContent: 'center'
				}}
			>
				<CSVLink
					data={getWebsites.data.data.websites}
					style={{ padding: '5px', display: 'flex' }}
					filename="trade-fair-app-data.csv"
				>
					<Tooltip title="Download as CSV" arrow placement="left">
						<DownloadIcon sx={{ color: 'rgb(36, 147, 242)' }} />
					</Tooltip>
				</CSVLink>
			</div>
			<TableContainer>
				<Table sx={customTableStyles.tableStyles} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={customTableStyles.cellStyles}>ID</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Url
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Company
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Email
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Employees
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Expertise
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Industries
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Phone
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Products
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Region
							</TableCell>
							<TableCell sx={customTableStyles.cellStyles} align="left">
								Services
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{getWebsites.data.data.websites
							?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row: any) => (
								<TableRow key={row.id} sx={customTableStyles.cellStyles3}>
									<TableCell
										component="th"
										scope="row"
										sx={customTableStyles.rowCellStyles}
									>
										{row.id?.slice(0, 15).concat('...')}
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.url}>
											{row?.url}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.company_name}>
											{row?.company_name}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.email}>
											{row?.email}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.employees}>
											{row?.employees}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.expertise}>
											{row?.expertise?.length <= 20
												? row?.expertise
												: row.expertise.slice(0, 20).concat('...')}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.industries}>
											{row?.industries}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.phone}>
											{row?.phone}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.products}>
											{row?.products?.length <= 20
												? row?.products
												: row?.products.slice(0, 20).concat('...')}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.region}>
											{row?.region}
										</Tooltip>
									</TableCell>
									<TableCell align="left" sx={customTableStyles.rowCellStyles}>
										<Tooltip followCursor title={row?.services}>
											{row?.services?.length <= 20
												? row?.services
												: row?.services?.slice(0, 20).concat('...')}
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={getWebsites.data.data.websites?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={{
					'.MuiIconButton-root': { color: '#1E9DF2' },
					'.MuiTablePagination-displayedRows': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					},
					'.MuiTablePagination-select': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					},
					'.MuiTablePagination-selectIcon': {
						color: '#1E9DF2'
					},
					'.MuiTablePagination-selectLabel': {
						fontSize: '14px',
						color: '#1B1E1F',
						fontFamily: 'Poppins',
						fontWeight: '500'
					}
					// width: "1100px"
				}}
			/>
		</Paper>
	);
}

const customTableStyles = {
	tableStyles: {
		borderTop: '1px solid #E8E8E9',
		borderBottom: '1px solid #E8E8E9',
		borderLeft: '1px solid #E8E8E9',
		borderRight: '1px solid #E8E8E9',
		boxShadow: 'none',
		maxWidth: '700px',
		// marginTop: '-40px',
		minWidth: '700px'
		// border: "2px solid red",
	},
	cellStyles: {
		borderTop: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderBottom: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderLeft: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		color: '#1B1E1F',
		fontSize: '11px',
		fontWeight: '500',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// height: "35px",
		minWidth: '100px',
		// padding: '8px 12px',
		// width: "max-content",
		// '& .MuiTableSortLabel-root': {
		// 	width: 'max-content'
		// },
		backgroundColor: '#F4F4F4'
	},
	cellStyles2: {
		border: '1px solid #E8E8E9',
		fontSize: '11px',
		fontWeight: '400',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '0px 12px',
		color: '#1B1E1F'
	},
	cellStyles3: {
		border: '1px solid #E8E8E9',
		fontSize: '11px',
		fontWeight: '400',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '10px 12px',
		color: '#1B1E1F',
		'&:nth-of-type(even)': { backgroundColor: '#f5f5f5' }
	},
	paperComponent: {
		// width: '1300px',
		minHeight: '460px',
		// mb: 2,
		boxShadow: 'none',
		background: 'transparent',
		paddingTop: '20px',
		fontFamily: 'Poppins'
	},
	cellStylesProject: {
		borderTop: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderBottom: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		borderLeft: '1px solid var(--main-grey-grey-10, #E8E8E9)',
		color: '#1B1E1F',
		fontSize: '11px',
		fontWeight: '500',
		fontStyle: 'normal',
		fontFamily: 'Poppins',
		// padding: '0px 12px',
		'& .MuiTableSortLabel-root': {
			width: 'max-content'
		},
		backgroundColor: '#F4F4F4'
	},
	rowCellStyles: {
		fontFamilu: 'Poppins',
		fontSize: '11px'
	}
};
