import ShowcaseCard, {
	ShowCaseCardProps
} from '../../components/cards/ShowcaseCard';
import { showcaseCardData } from './showcaseCardsData';
import CssCard from '../../components/cards/ShowCaseCard/CssCard';

const Home = () => {
	return (
		<div
			style={{
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center'
			}}
		>
			<p style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '500' }}>
				Demo Applications
			</p>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-evenly',
					padding: '20px 0px'
				}}
			>
				{showcaseCardData.map((card: ShowCaseCardProps, index: number) => {
					return (
						<ShowcaseCard
							key={index}
							img={card.img}
							title={card.title}
							content={card.content}
							link={card.link}
						/>
					);
				})}
			</div>
		</div>
	);
};
export default Home;
