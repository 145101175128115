import axios, { AxiosResponse } from "axios";
import { errorHandler, options } from './index';
import { API_URL } from '../config';

type crawlerProps = {
	url: string
}

export async function startCrawler(data:any) {
	// console.log('data', API_URL)
		try {
			const response = await axios(options(API_URL + '/crawler', {data}, 'post', ''));
			const responseOK = response && response.status === 200 && response.statusText === 'OK';
			if (responseOK) {
				
        return response
			}
		} catch (error) {
			console.warn('Error:', error);
		}
	
}

export function getCrawlerStatus() {

axios.get(`${API_URL}/crawler`)
	
}

export async function crawlerAI(){
	try {
		const response = await axios(options(API_URL + '/openAI',{},  'get'));
		const responseOK = response && response.status === 200 && response.statusText === 'OK';
		if (responseOK) {
			
			return response
		}
	} catch (error) {
		console.warn('Error:', error);
	}
}

interface PostsService {
  crawl: (data: crawlerProps) => Promise<AxiosResponse<crawlerProps>>;
}

export const crawlerService = {
  crawl: (url:crawlerProps) => axios.post(`${API_URL}/crawler`, url),
}


