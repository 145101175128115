import { useEffect, useState } from 'react';
import Login from '../../components/auth/SignIn';
import AdminDashboard from '../../pages/admin/AdminDashboard';
import { useSelector } from 'react-redux';
import { checkUserExist, getUser } from '../../hooks/getUser';

const CustomerManager = () => {
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

	const { user } = useSelector((globalState: any) => ({
		user: getUser()
	}));

	useEffect(() => {
		if (user) {
			setIsLoggedIn(true);
		}
	}, [user]);

	let content;

	if (!isLoggedIn) {
		content = <Login setIsLoggedIn={setIsLoggedIn} />;
	} else {
		content = <AdminDashboard setIsLoggedIn={setIsLoggedIn} />;
	}

	return content;
};

export default CustomerManager;
