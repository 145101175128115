import { ShowCaseCardProps } from "../../components/cards/ShowcaseCard";
import CustomerManager from "../../assets/img/other/customer-manager.png"
import TradeFair from "../../assets/img/other/trade-fair.jpg"
import ChatBot from "../../assets/img/other/chatbot.jpeg"


export const showcaseCardData: ShowCaseCardProps[] = [
	{
		img: CustomerManager,
		title: 'Customer Manager',
		content: 'Experience the power of streamlined customer management with our intuitive dashboards. Gain actionable insights at a glance, track customer interactions, monitor sales trends, and optimize your strategies effortlessly. Unlock the potential of your customer relationships with real-time data visualization and analytics.',
		link: '/customer-manager'
	},
	{
		img: TradeFair,
		title: 'Trade Fair App',
		content: 'Effortlessly navigate trade fairs with our Trade Fair app. Seamlessly filter through exhibitors according to your preferences and interests. Simply input company websites to gather essential information for each exhibitor, ensuring you build the most relevant connections. Our  filtering solution minimizes time spent and maximizes productivity at every upcoming trade fair or expo.',
		link: '/trade-fair-app'
	},
	{
		img: ChatBot,
		title: 'Open AI ChatBot',
		content: 'Engage your customers effectively with our Open AI Chatbot. Powered by advanced artificial intelligence, our chatbot provides personalized interactions and instant support around the clock. Through continuous learning from conversations, it enhances its responses over time. Improve your customer service experience, increase conversions, and streamline communication with our intelligent chatbot solution.',
		link: '/chatbot'
	},
];
