import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props {
  icon: any,
  title: string,
  rate: any,
  startDate: string,
  endDate: string,
  value: number
}

const WidgetCard: React.FC<Props> = ({ icon, title, rate, startDate, endDate, value }) => {
  return (
    <Box 
        sx={{
            width: "100%", 
            backgroundColor: "#fff", 
            border: "1px solid rgba(0, 0, 0, 0.12)", 
            margin: "1rem 0", 
            padding: "20px", 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: "center",
            gap: "4rem",
            flexWrap: 'wrap'
        }}
    >
        <Box 
            sx={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: "center"
            }}>
            {icon}
        </Box>

        <Box>
            <Typography sx={{fontWeight: '500'}}>{title}</Typography>
            <Typography sx={{fontWeight: '500'}}>{rate}</Typography>
            <Typography sx={{color: "gray", fontSize: '.9rem'}}>{startDate} - {endDate}</Typography>
            <Typography sx={{color: "gray", fontSize: '.9rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px'}}>
                <span style={{color: '#4CBB17', fontWeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <KeyboardArrowUpIcon sx={{fontSize: "1.1rem"}} />{value}%
                </span>
                Since last month
            </Typography>
        </Box>
    </Box>
  );
};

export default WidgetCard;
