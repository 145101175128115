/* eslint-disable */
import { useState } from 'react';
import { Typography } from '@mui/material';
import { sectionStyles } from '../../theme/sectionStyles';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

interface Props {
	data2022: number[];
	data2023: number[];
}

const LineChart: React.FC<Props> = ({ data2022, data2023 }) => {
	const classes = sectionStyles;
	const { t, i18n } = useTranslation();

	const series = [
		{
			name: '2022',
			data: data2022
		},
		{
			name: '2023',
			data: data2023
		}
	];

	const options: any = {
		chart: {
			height: 350,
			type: 'line'
		},
		colors: ['#378fe9', '#83941f'],
		xaxis: {
			categories:
				i18n.language === 'de'
					? [
							'Januar',
							'Februar',
							'März',
							'April',
							'Mai',
							'Juni',
							'Juli',
							'August',
							'September',
							'Oktober',
							'November',
							'Dezember'
					  ]
					: [
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December'
					  ]
		},
		series: {
			stroke: {
				curve: 'smooth'
			}
		},
		stroke: {
			width: [3, 3],
			lineCap: 'round',
			lineJoin: 'round',
			dashArray: [0, 5],
			colors: ['#378fe9', '#83941f']
		}
	};

	return (
		<div
			id="chart"
			style={{
				width: '100%',
				backgroundColor: '#fff',
				border: '1px solid rgba(0, 0, 0, 0.12)',
				marginTop: '3rem'
			}}
		>
			<Typography
				component="div"
				sx={classes.contentHeader}
				style={{ margin: '-40px 10px 20px 10px' }}
			>
				<Typography component="h2" sx={classes.title}>
					{t('core:annualSales')}
				</Typography>
			</Typography>
			<ReactApexChart
				options={options}
				series={series}
				type="line"
				height={350}
			/>
		</div>
	);
};

export default LineChart;
