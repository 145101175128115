import { Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { sectionStyles } from "../../theme/sectionStyles";
import { useTranslation } from "react-i18next";
import { columnData as chartData } from './chartData';

function ColumnChart() {
    const classes = sectionStyles;
    const { t, i18n } = useTranslation(); 

    const chartOptions = {
        colors: ['#378fe9', '#83941f'],
        chart: {
            id: 'basic-column'
        },
        xaxis: {
            categories: i18n.language === 'de' ? [
              'Januar',
              'Februar',
              'März',
              'April',
              'Mai',
              'Juni',
              'Juli',
              'August',
              'September',
              'Oktober',
              'November',
              'Dezember',
            ] : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
          },
        // yaxis: {
        //     title: {
        //     text: 'Amount'
        //     }
        // },
        legend: {
            position: 'bottom' as const
        }
    };

    return (
    <div id="chart" style={{width: "100%", backgroundColor: "#fff", border: "1px solid rgba(0, 0, 0, 0.12)", marginTop: "3rem"}}>
        <Typography component="div" sx={classes.contentHeader} style={{margin: "-40px 10px 20px 10px",}}>
            <Typography component="h2" sx={classes.title}>
                {t('core:annualProfitAndExpences')}
            </Typography>
        </Typography>
        <ReactApexChart
            options={chartOptions}
            series={chartData}
            type="bar"
            height={350}
        />
    </div>
    );
}

export default ColumnChart;
