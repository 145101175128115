import './fullScreenChat.css';
import axios from 'axios';
import { API_URL } from '../../../config';
import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { useQueryClient } from '@tanstack/react-query';

const FullScreenChat = (props: any) => {
	const { email, name } = props;

	const queryClient = useQueryClient();

	const [messages, setMessages] = useState([
		{ text: 'Hallo? Wie kann ich Ihnen helfen?', sender: 'bot' }
	]);
	const [inputValue, setInputValue] = useState<string>('');
	const [activateChat, setActivateChat] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const messageRef: any = useRef();

	useEffect(() => {
		setMessages(messages);
		if (messageRef.current) {
			messageRef.current.scrollIntoView({
				behavior: 'smooth',
				inline: 'nearest'
			});
		}
	}, [messages]);

	const sendMessage = () => {
		const message = inputValue.trim();

		if (message) {
			setMessages((messages) => [
				...messages,
				{ text: message, sender: 'user' }
			]);

			setInputValue('');

			setIsLoading(true);
			axios
				.post(`${API_URL}/openAI`, {
					queryResult: {
						action: 'input.unknown',
						queryText: `${message}`,
						data: { email: email, name: name }
					}
				})
				.then((data) => {
					setMessages((messages) => [
						...messages,
						{ text: data.data.message, sender: 'bot' }
					]);
					setIsLoading(false);
					console.log('data.data.message', data.data.message);
					if (
						data.data.message ===
						'Es wurde ein Ticket zu Ihrer Anfrage erstellt. Innerhalb von 24 Stunden wird sich unser Team bei Ihnen melden.'
					) {
						queryClient.invalidateQueries({ queryKey: ['tickets'] });
					}
					return true;
				})
				.catch((err) => console.log(err));
		}
	};

	const handleInputKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			sendMessage();
		}
	};

	return (
		<div className="chat-container">
			<Card sx={{ minWidth: 700, maxWidth: 700 }}>
				<CardContent
					sx={{ minHeight: '400px', maxHeight: '400px', overflowY: 'auto' }}
				>
					<div className="chat-messages">
						{messages.map((message, index) => {
							return (
								<p
									key={index}
									className={`message ${message.sender}`}
									ref={messageRef}
								>
									{message.text}
								</p>
							);
						})}
					</div>
				</CardContent>
			</Card>
			<div>
				<div style={{ display: 'flex', width: '100%' }}>
					<TextField
						variant="filled"
						fullWidth
						type="text"
						placeholder="Type your message here..."
						value={inputValue}
						onChange={(event) => setInputValue(event.target.value)}
						onKeyPress={handleInputKeyPress}
						sx={{ margin: 0, '& .MuiInputBase-input': { padding: '15px' } }}
					/>
					<LoadingButton
						loading={isLoading}
						loadingPosition="center"
						sx={{ borderRadius: 0 }}
						variant="contained"
						onClick={sendMessage}
					>
						<SendIcon />
					</LoadingButton>
				</div>
			</div>
		</div>
	);
};

export default FullScreenChat;
